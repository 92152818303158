import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ROUTES} from '../../../constants/routes';
import Button from '../../Common/Button';
import {Typography} from '../../Common/Typography';
import {DateTime} from 'luxon';
import {getRangeOfTimeRecords, startTimeRecord, stopTimeRecord} from '../../../api/timeTracker';
import {ClipLoader} from 'react-spinners';
import {Duration} from 'luxon';
import {loadCedarOptions, updateWorkingState} from '../../../store/timeTracking';
import useInterval from '../../../hooks/useInterval';
import Icon from '../../Common/Icon';
//Constants
export const CEDAR_TYPES = {
	work: 'work',
	pause: 'pause',
	mandatoryPause: 'mandatoryPause',
	stop: 'stop',
	sickLeave: 'sickLeave',
	vacation: 'vacation',
};
function TimeTracker() {
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [loadingUI, setLoadingUI] = useState(true);
	const [loadingTimer, setLoadingTimer] = useState(true);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const selectedOfficeId = selectedOffice._id;
	const timezone = selectedOffice?.timezone;
	const userData = useSelector((state) => state.auth.data.userData);
	const cedarOptions = useSelector((state) => state.cedar.options);

	const [duration, setDuration] = useState(Duration.fromMillis(0));
	const isFirstDurationSet = useRef(true);

	const [allTimeRecords, setAllTimeRecords] = useState([]);

	const [trackerView, setTrackerView] = useState({
		disabled: false,
		reason: null,
		nextBookingPeriod: null,
	});

	const lastTimeRecord = allTimeRecords[allTimeRecords.length - 1];

	useEffect(() => {
		if (selectedOffice && cedarOptions) {
			updateTrackerView();
		}
	}, [lastTimeRecord, selectedOffice, cedarOptions]);

	function updateTrackerView() {
		if (!lastTimeRecord?.end?.time && lastTimeRecord?.maxEnd?.time) {
			// Fetch again after maxEnd is reached to get new records created in BE
			const now = DateTime.now();
			const maxEndDateTime =  DateTime.fromISO(lastTimeRecord?.maxEnd?.time)
			const timeUntilMaxEnd = maxEndDateTime.diff(now).as('milliseconds');
			if (timeUntilMaxEnd > 0) {
				setTimeout(updateAllTimeRecords, timeUntilMaxEnd)
			}
		}

		if (lastTimeRecord?.name === CEDAR_TYPES.mandatoryPause && !lastTimeRecord?.end) {
			// Set view to pauseRule because user is currently doing a mandatoryPause
			setTrackerView({
				disabled: true,
				reason: 'pauseRule',
				nextBookingPeriod: DateTime.fromISO(lastTimeRecord?.maxEnd?.time),
			});
			return;
		}

		const maxWorkHoursReached = areMaxWorkHoursReached();
		if (maxWorkHoursReached) {
			// Set view to maxWorkingHours because the user has reached their maximum working hours
			setTrackerView({
				disabled: true,
				reason: 'maxWorkingHours',
			});
			return;
		}
		
		const outsideOfficeHoursView = isOutsideOfficeHours();
		if (outsideOfficeHoursView?.show) {
			// Set view to outsideOfficeHours because user is not within a office hour period
			setTrackerView({
				disabled: true,
				reason: 'outsideOfficeHours',
				nextBookingPeriod: outsideOfficeHoursView.nextBookingPeriod,
			});
			// Set a timeout to check again when the next booking period starts
			if (outsideOfficeHoursView.nextBookingPeriod) {
				const now = DateTime.now();
				const timeUntilNextPeriod = outsideOfficeHoursView.nextBookingPeriod.diff(now).as('milliseconds');
				setTimeout(updateAllTimeRecords, timeUntilNextPeriod);
			}
			return;
		}

		setTrackerView({
			disabled: false,
			reason: null,
			nextBookingPeriod: null,
		});
	}

	const [buttonStatus, setButtonStatus] = useState();

	function isOutsideOfficeHours() {
		if (!selectedOffice || !cedarOptions?.options?.restrictToOfficeHours) {
            return {show: false, nextBookingPeriod: null};
        }
		const now = DateTime.now(); // Get current date and time using Luxon
		const dayOfWeek = now.weekday; // Monday - Sunday : 1 - 7
		const currentTimeInMs = now.diff(now.startOf('day')).toObject().milliseconds; // Time since start of day in ms

		// Find today's schedule
		const todaySchedule = selectedOffice?.openingHours?.office?.schedule?.find((day) => day.weekday === dayOfWeek);

		if (!todaySchedule?.periods || todaySchedule?.open24Hours || todaySchedule?.periods?.length === 0) {
			// If there is no schedule for today, we assume it's outside office hours.
			return {show: false, nextBookingPeriod: null};
		}


		// Check if the current time is within any of the periods
		const isInOfficeHours = todaySchedule?.periods?.some((period) => {
			return currentTimeInMs >= period?.open && currentTimeInMs <= period?.close;
		});
		
		if (isInOfficeHours) {
			return {show: false, nextBookingPeriod: null};
		} else {
			return {show: true, nextBookingPeriod: findNextBookingPeriod(now, dayOfWeek)};
		}
	}

	function areMaxWorkHoursReached() {
		if (!duration) return false;

		const now = DateTime.now(); // Get current date and time using Luxon
		const dayOfWeek = now.weekday; // Monday - Sunday : 1 - 7
		const todaysDefaultWorkTimePerDay = cedarOptions?.defaultWorkTimePerDay?.find((day) => day.weekday === dayOfWeek);
		if (!todaysDefaultWorkTimePerDay?.maxWorkingHours) {
			return false;
		}

		const totalWorkDuration = calculateTotalWorkDuration(allTimeRecords);
		return totalWorkDuration.toMillis() >= todaysDefaultWorkTimePerDay?.maxWorkingHours;
	}

	function findNextBookingPeriod(now, currentDayOfWeek) {
		let nextDay = currentDayOfWeek;
		let daysChecked = 0;

		while (daysChecked < 7) {
			const scheduleToCheck = selectedOffice?.openingHours?.office?.schedule?.find((day) => day.weekday === nextDay);

			if (scheduleToCheck && scheduleToCheck?.periods && scheduleToCheck.periods.length > 0) {
				// If it's the current day, find the next period after the current time
				if (nextDay === currentDayOfWeek) {
					const currentTimeInMs = now.diff(now.startOf('day')).toObject().milliseconds;
					const nextPeriod = scheduleToCheck?.periods?.find((period) => period.open > currentTimeInMs);

					if (nextPeriod) {
						return now.setZone(timezone).startOf('day').plus({milliseconds: nextPeriod.open});
					}
				} else {
					// For future days, return the first period
					return now.plus({days: daysChecked}).setZone(timezone).startOf('day').plus({milliseconds: scheduleToCheck.periods[0].open});
				}
			}

			nextDay = (nextDay % 7) + 1; // Move to the next day, wrapping around to 1 after 7
			daysChecked++;
		}

		// If no booking period found in the next 7 days, return null
		return null;
	}
	const handleUpsertCedarRecord = (updatedTimeRecord) => {
		setAllTimeRecords((previousTimeRecords) => {
			const recordExists = previousTimeRecords.some((record) => record._id === updatedTimeRecord._id);
			if (!recordExists) {
				//add end time to time record without endtime
				const updatedCurrentTimeRecords = previousTimeRecords.map((record) => {
					if (!record.end) {
						return {
							...record,
							end: updatedTimeRecord.start,
						};
					}
					return record;
				});
				return [...updatedCurrentTimeRecords, updatedTimeRecord];
			}
			return previousTimeRecords.map((record) => (record._id === updatedTimeRecord._id ? updatedTimeRecord : record));
		});
	};

	async function updateAllTimeRecords() {
		setLoadingUI(true);
		try {
			const startTime = DateTime.now().startOf('day').setZone(timezone);
			const endTime = DateTime.now().endOf('day').setZone(timezone);
			const todaysTimeRecords = await getRangeOfTimeRecords(selectedOfficeId, startTime, endTime);
			setAllTimeRecords(todaysTimeRecords?.data);
		} catch (err) {
			console.warn('There was an issue while mounting the TimeTrack Component' + err);
		} finally {
			setLoadingUI(false);
		}
	}

	useEffect(() => {
		dispatch(loadCedarOptions(selectedOffice?._id));
		updateAllTimeRecords();
	}, []);

	useEffect(() => {
		if (isFirstDurationSet.current && duration) {
			updateTrackerView();
			isFirstDurationSet.current = false;
		}
	}, [duration]);

	useInterval(() => {
		if (allTimeRecords?.length > 0) {
			const totalWorkDuration = calculateTotalWorkDuration(allTimeRecords);
			setDuration(totalWorkDuration);
		}
	}, 1000);

	useEffect(() => {
		let cedarType = CEDAR_TYPES.stop; // Default to 'stop'

		if (!allTimeRecords?.length) {
			setView(cedarType);
			dispatch(updateWorkingState(cedarType));
			return;
		}

		const {end, name} = lastTimeRecord;

		if (end?.time === undefined) {
			cedarType = name;
		}

		setView(cedarType);
		dispatch(updateWorkingState(cedarType));
	}, [allTimeRecords]);

	function handleSeeAll() {
		history.push(ROUTES.TIME_TRACKER.MAIN);
	}

	const handleProgress = (type) => {
		// eslint-disable-next-line default-case
		switch (type) {
			case CEDAR_TYPES.work: {
				setLoadingTimer(true);
				startTimeRecord({name: CEDAR_TYPES.work, office: selectedOfficeId})
					.then((newTimeRecord) => handleUpsertCedarRecord(newTimeRecord.data))
					.catch((err) => console.log('An error occured while ongoing timer: ', err))
					.finally(() => setLoadingTimer(false));
				break;
			}
			case CEDAR_TYPES.pause: {
				setLoadingTimer(true);
				startTimeRecord({name: CEDAR_TYPES.pause, office: selectedOfficeId})
					.then((newTimeRecord) => handleUpsertCedarRecord(newTimeRecord.data))
					.catch((err) => console.log('An error occured while pressing pause:', err))
					.finally(() => setLoadingTimer(false));
				break;
			}
			case CEDAR_TYPES.stop: {
				setLoadingTimer(true);
				stopTimeRecord({office: selectedOfficeId})
					.then((updateTimeRecord) => handleUpsertCedarRecord(updateTimeRecord.data))
					.catch((err) => console.log('An error occured while pressing pause:', err))
					.finally(() => setLoadingTimer(false));
				break;
			}

			default: {
				console.log('No state transition found for TimeTracking');
			}
		}
	};

	const setView = (type) => {
		setLoadingTimer(true);
		switch (type) {
			case CEDAR_TYPES.pause: {
				setButtonStatus({work: true, pause: false, stop: true});
				break;
			}
			case CEDAR_TYPES.work: {
				setButtonStatus({work: false, pause: true, stop: true});
				break;
			}
			case CEDAR_TYPES.stop: {
				setButtonStatus({work: true, pause: false, stop: false});
				break;
			}
			default: {
				break;
			}
		}
		setLoadingTimer(false);
	};

	function calculateTotalWorkDuration(records) {
		let totalDuration = Duration.fromObject({hours: 0});
		if (!records) {
			return totalDuration;
		}

		records.forEach((record) => {
			if (record.name === CEDAR_TYPES.work) {
				const start = DateTime.fromISO(record.start.time);
				const end = record?.end?.time ? DateTime.fromISO(record?.end?.time) : DateTime.now();
				const duration = end.diff(start);
				totalDuration = totalDuration.plus(duration);
			}
		});

		return totalDuration;
	}

	return loadingUI === true ? (
		<div className="ph-item">
			<div className="ph-row">
				<div className="ph-col-12"></div>
			</div>
		</div>
	) : (
		<div className="time-tracker-wrapper">
			<div className="time-tracker-wrapper-header">
				<Typography className="header" variant={'title-large'}>
					{t('dashboard.timeTracker.header')}
				</Typography>

				<div>
					<Button
						cssClass={'show-all'}
						variant={'tertiary'}
						height={'regular'}
						width={'variable'}
						clickHandler={() => handleSeeAll()}
						translationKey={'common.seeAll'}
						iconName={'fas fa-angles-right'}
						iconPlacement={'right'}
					/>
				</div>
			</div>

			<div className={'time-tracker-wrapper-container ' + (trackerView.disabled && 'overworking')}>
				{loadingTimer === true ? (
					<div className="loading-wrapper">
						<ClipLoader size={100} color="white" />
					</div>
				) : (
					<div>
						{trackerView?.disabled ? (
							<>
								<p className="timer-header">
									{trackerView.reason === 'maxWorkingHours' && t('dashboard.timeTracker.subHeaderHourLimit')}
									{trackerView.reason === 'pauseRule' && t('dashboard.timeTracker.subHeaderBreak')}
									{trackerView.reason === 'outsideOfficeHours' && t('dashboard.timeTracker.outsideOfficeHours')}
								</p>
								<p className="timer-description">
									{trackerView.reason === 'maxWorkingHours' && t('dashboard.timeTracker.descriptionHourLimit')}
									{trackerView.reason === 'pauseRule' &&
										t('dashboard.timeTracker.descriptionBreak', {
											count: duration?.toFormat('h'),
										})}
									{trackerView.reason === 'outsideOfficeHours' && t('dashboard.timeTracker.descriptionOutsideOfficeHours')}
								</p>
							</>
						) : buttonStatus?.pause || buttonStatus?.stop ? (
							<>
								<p className="timer-header">{t('dashboard.timeTracker.subHeaderStart')}</p>
								<p className="timer-description">{t('dashboard.timeTracker.descriptionStart')}</p>
							</>
						) : (
							<>
								<p className="timer-header">{t('dashboard.timeTracker.subHeader')}</p>
								<p className="timer-description">{t('dashboard.timeTracker.description')}</p>
							</>
						)}
						<hr />
						<div className="time-tracker-wrapper-container-buttons">
							<p className="timer">{duration?.toFormat('hh:mm:ss')}</p>
							{!trackerView.disabled ? (
								<div>
									{buttonStatus?.work && (
										<Button
											variant={'tertiary'}
											height={'regular'}
											clickHandler={() => handleProgress(CEDAR_TYPES.work)}
											iconName={'fa-regular fa-circle-play'}
											cssClass={'playTimerDashboard'}
											loading={loadingTimer}
										/>
									)}
									{buttonStatus?.pause && (
										<Button
											variant={'tertiary'}
											height={'regular'}
											clickHandler={() => handleProgress(CEDAR_TYPES.pause)}
											iconName={'fa-regular fa-circle-pause'}
											cssClass={'pauseTimerDashboard'}
											loading={loadingTimer}
										/>
									)}
									{buttonStatus?.stop && (
										<Button
											variant={'tertiary'}
											height={'regular'}
											clickHandler={() => handleProgress(CEDAR_TYPES.stop)}
											iconName={'fa-regular fa-circle-stop'}
											cssClass={'resumeTimerDashboard'}
											loading={loadingTimer}
										/>
									)}
								</div>
							) : (
								<>
									<Icon iconName="fa-solid fa-circle-exclamation" cssClass="icon-red" />
								</>
							)}
						</div>
						{trackerView.disabled && (
							<span class="grey-out-error">
								{trackerView?.reason === 'pauseRule' &&
									t('dashboard.timeTracker.timeForABreakError', {
										count: trackerView?.nextBookingPeriod?.toFormat('HH:mm'),
									})}
								{trackerView?.reason === 'outsideOfficeHours' &&
									t('dashboard.timeTracker.outsideOfficeHoursError', {
										count: trackerView?.nextBookingPeriod?.toLocaleString(DateTime.DATETIME_SHORT),
									})}
							</span>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default TimeTracker;
