import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import msTeamsIcon from '../../image/teams.png';
import Icon from '../Common/Icon';
import { ROUTES } from '../../constants/routes';
import { enableBottomMenu, getOfficeBasedOnId, getTeakTypeObj } from '../../store/auth';
import SecondaryHeader from '../Common/Header/SecondaryHeader';
import Button from '../Common/Button';
import AddPeople from '../Booking/ConfirmBooking/AddPeople';
import { CARD } from '../../constants/cards';
import { enableCard, getAreaNameBasedOnId, updateConfirmationCard, updateErrorCard } from '../../store/cards';
import { bookingStatus } from '../../constants/bookingStatus';
import { VisitorList } from '../VisitorManagement/MeetingSummary';
import FormattedDateTime from '../Common/DateTimePicker/FormattedDateTime';
import { FeaturesChipList } from '../Common/Chip/FeaturesChipList';
import { getTeakTypeName } from '../Booking/BookingPage/AssetTypes';
import { confirmActionsMap } from '../Cards/Common/ConfirmAction/confirmActions';
import { getBooking } from '../../api/teakBooking';
import { updateDetailsBookingData } from '../../store/bookingsOverview/bookings';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import { getTeakEntitiesFull } from '../../functions/getTeakEntitiesFull';
import { updateAttendeeList } from '../../store/bookingProcess/entitiesBooking';
import { TEAK_TYPE_NAMES } from '../../constants/teakTypeNames';

export function filteredAttendeeList(attendeeList, bookingData, userData) {
	function addCancelledStatusToAttendee(attendeeList) {
		return attendeeList.map((item) => {
			if (!item?.cancelled) return item;
			return { ...item, member: { ...item.member, cancelled: item.cancelled } };
		});
	}
	const attendeeListWithStatus = addCancelledStatusToAttendee(attendeeList);
	//filter the host and you out of the attendee list
	if (attendeeListWithStatus.length <= 0) return;

	return attendeeListWithStatus.filter((item) => {
		return item?.member?._id !== bookingData?.member?._id && item?.member?._id !== userData?._id && item;
	});
}
function BookingDetails() {
	const location = useLocation();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useCustomNavigation();
	const userData = useSelector((state) => state.auth.data.userData);
	const organizationData = useSelector((state) => state.auth.data.organizationData);
	const data = useSelector((state) => state.bookings.detailsBookingData);
	const adminEmail = useSelector((state) => state.auth.data.selectedOffice.email);
	const entityData = data?.entity;
	const maxOccupancy = entityData?.maxOccupancy;
	const bookingData = data?.booking;
	const isMSOutlookRoom = !!entityData?.microsoft?.identifier;
	const msOutlookLink = bookingData?.microsoft?.onlineMeeting?.joinUrl;
	const areasFromStore = useSelector((state) => state.auth.data.areas);
	const featureTranslations = useSelector((state) => state.auth.data.featureTranslations);
	const teakFeaturesFromStore = useSelector((state) => state.auth.data.teakFeatures);
	let attendeeList = bookingData?.attendees ? [...bookingData?.attendees] : [];
	let { name = 'name', areaName = undefined } = entityData || {};
	areaName = useSelector(getAreaNameBasedOnId(entityData?.area._id ?? entityData?.area));
	const officeIdOfEntity = bookingData?.office?._id || entityData?.office._id || entityData?.office;
	const officeObjectOfEntity = useSelector(getOfficeBasedOnId(officeIdOfEntity));
	const officeTimezone = officeObjectOfEntity?.timezone;
	const officeName = officeObjectOfEntity?.name;
	const teakTypeObj = useSelector(getTeakTypeObj(entityData?.teakType));

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		if (location?.state?.bookingId) {
			addBookingDataFromId(location.state.bookingId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	async function addBookingDataFromId(bookingId) {
		try {
			const bookingResponse = await getBooking(bookingId);
			const teakEntityFull = getTeakEntitiesFull(areasFromStore, featureTranslations, userData, teakFeaturesFromStore, [
				bookingResponse.data.teakEntity,
			]);
			const booking = bookingResponse.data;
			const attendees = booking?.attendees;
			dispatch(updateDetailsBookingData({ booking: booking, entity: teakEntityFull[0] }));
			dispatch(updateAttendeeList(attendees))
		} catch (error) {
			dispatch(enableCard(true));
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.bookingNotFound'));
			closeCurrentPage();
		}
	}
	const cancelBooking = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CANCEL_BOOKING,
				{
					headerKey: 'confirmDelete.confirmMessage',
					confirmButtonKey: 'common.cancelBooking',
					infoKey: data?.booking?.pineInvitation && 'confirmDelete.confirmDeleteBookingWithPineInvitation',
				},
				{ ...data, organizationData },
			),
		);
	};

	const closeCurrentPage = () => {
		navigate.push(ROUTES.DEFAULT);
	};

	const backButtonHandler = () => {
		navigate.goBack();
	};

	function renderBookedByInformation() {
		const bookedBy = bookingData?.bookedBy;
		const member = bookingData?.member;
		let memberName = `${member?.name?.first} ${member?.name?.last}`;
		if (!member?.name?.first && !member?.name?.last) {
			memberName = userData?.name?.full;
		}

		if (member?._id !== userData?._id) {
			//If we are only a attendee
			return (
				<span>
					{memberName}&nbsp;
					{t('yourBooking.headers.host')}
				</span>
			);
		}

		if (bookedBy) {
			//If a colleague booked it and we are the host
			// If member exists, somebody booked the entity for this member. If user exists, an admin booked the entity for this member
			return <span>{bookedBy.member ? bookedBy?.member?.name?.full : bookedBy.user?.name?.full}</span>;
		}

		return (
			//We booked it
			<span>
				{member?.name?.full}&nbsp;
				{t('yourBooking.headers.you')}
			</span>
		);
	}

	function isUserAttendee() {
		if (bookingData?.member?._id === userData?._id) return false;
		return attendeeList.find((item) => {
			return item?.member?._id === userData?._id;
		});
	}

	const handleCancelAttendance = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CANCEL_ATTENDANCE,
				{
					headerKey: 'confirmDelete.confirmCancelAttendance',
					confirmButtonKey: 'common.cancelAttendance',
				},
				data,
			),
		);
	};

	return (
		<div className="booking-details-wrapper">
			<SecondaryHeader
				backButtonClickHandler={backButtonHandler}
				closeButtonClickHandler={closeCurrentPage}
				headerTranslationKey={t('yourBooking.headers.yourBooking')}
			/>
			<div className="container">
				<div className="booking-information">
					<p className="title">{t('yourBooking.headers.title')}</p>
					<div className="entity-information">
						<p className="info-entity">
							{t('yourBooking.headers.bookedBy')}: {renderBookedByInformation()}
						</p>
						{bookingData?.meetingName?.trim() && (
							<p className="info-entity">
								{t('common.meetingName')}: <span>{bookingData?.meetingName}</span>
							</p>
						)}
						<p className="info-entity">
							{getTeakTypeName(teakTypeObj, t)}: <span>{name}</span>
						</p>
						<p className="info-entity">
							{t('common.location')}: <span>{areaName}</span>
						</p>
						<p className="info-entity">
							{t('common.office')}: <span>{officeName} </span>
						</p>
						<p className="info-entity">
							{t('common.dateTimePicker.from')}:{' '}
							<span>
								{' '}
								<FormattedDateTime dateTime={bookingData?.start?.time} timezone={officeTimezone} />{' '}
							</span>
						</p>
						<p className="info-entity">
							{t('common.dateTimePicker.to')}:{' '}
							<span>
								{' '}
								<FormattedDateTime dateTime={DateTime.fromISO(bookingData?.end?.time).toISO()} timezone={officeTimezone} />{' '}
							</span>
						</p>
						{maxOccupancy && (
							<p className="info-entity">
								{t('common.meetingRoomCapacity')}: <span>{maxOccupancy} </span>
							</p>
						)}
						<div className="entity-features">
							<p className="info-entity">{t('common.features')}:</p>
							<div className="features-content">{<FeaturesChipList entityData={entityData} />}</div>
						</div>
					</div>
					{isMSOutlookRoom && msOutlookLink && (
						<div className="ms-teams-container">
							<p className="title">{t('yourBooking.msTeams.header')}</p>
							<div className="link-section">
								<img alt="ms-teams-png" src={msTeamsIcon} />
								<a target="_blank" rel="noreferrer" href={String(msOutlookLink)}>
									{t('yourBooking.msTeams.description')}
								</a>
							</div>
						</div>
					)}
					{(teakTypeObj?.__t === TEAK_TYPE_NAMES.ROOM) && (<>
						<AddPeople
							type={'required'}
							showAddMoreButton={false}
							showRemoveButton={false}
							showHideMore={false}
							hasMSOutlook={isMSOutlookRoom}
						/>
						<AddPeople
							type={'optional'}
							showAddMoreButton={false}
							showHideMore={false}
							showRemoveButton={false}
							hasMSOutlook={isMSOutlookRoom}
						/>
					</>)
					}
				</div>
				<hr></hr>
				{bookingData?.pineInvitation && <PineInvitationData pineInvitation={bookingData?.pineInvitation} />}
				{bookingData?.seriesId && (
					<div className="recurring-booking-info">
						<p className="title">{t('yourBooking.headers.repeatThisBooking')}</p>
						<div className="info-section">
							<Icon cssClass="icon-white" iconName="exclamation-circle" />
							<p>{t('yourBooking.repeatBookingInfo')}</p>
						</div>
					</div>
				)}
				{bookingData?.status === bookingStatus.cancelled && bookingData?.cancelled?.comment && (
					<div className="booking-information">
						<p className="title">Info</p>
						<div className="cancellation-reason">
							<span className="reason-title">{t('yourBooking.headers.cancellationReason')}</span>
							<span className="reason-text">{bookingData?.cancelled?.comment}</span>
						</div>
						{t('notifications.notificationDetails.info')}
						&nbsp;
						{adminEmail
							? t('notifications.notificationDetails.infoWithEmail', { adminEmail })
							: t('notifications.notificationDetails.infoWithoutEmail')}
					</div>
				)}
			</div>

			{(bookingData?.status === bookingStatus.confirmed || bookingData?.status === bookingStatus.checkInOpen) && (
				<div className="button-fixed-at-bottom-static">
					{isUserAttendee() ? (
						<Button
							cssClass={'btn-cancel'}
							variant={'negative'}
							height={'regular'}
							width={'full'}
							clickHandler={handleCancelAttendance}
							translationKey={'common.cancelAttendance'}
						/>
					) : (
						<Button
							variant={'negative'}
							height={'regular'}
							width={'full'}
							clickHandler={cancelBooking}
							translationKey={'common.cancelBooking'}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export function PineInvitationData({ pineInvitation }) {
	const { t } = useTranslation();

	return (
		<>
			<div className="pine-invitation-details">
				<p className="title">{t('visitorManagement.newMeeting.visitors')}</p>
				<VisitorList attendees={pineInvitation?.attendees} />
			</div>
			<hr className="dividing-hr"></hr>
			<div className="pine-invitation-summary">
				{pineInvitation?.description ? (
					<>
						<p className="title">{t('visitorManagement.newMeeting.meetingDetails')}</p>
						<p className="info-item info-meeting-details">{pineInvitation.description}</p>
					</>
				) : null}
			</div>
		</>
	);
}
export default BookingDetails;
