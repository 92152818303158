import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import { enableBottomCard, loadBackgroundImage, setOfficeElementsToHighlight, updateBottomCardData, updateHighlightEntityData } from './mapView';
import { getTeakEntitiesFull } from '../../functions/getTeakEntitiesFull';
import { getAreasInRange } from '../../api/areas';
import sortAlphabetically from '../../functions/sortAlphabetically';
import async from 'async';
import { roundTimeQuarterHour } from '../../functions/roundTimeToNextQuarter';
import { TEAK_TYPE_NAMES } from '../../constants/teakTypeNames';
import { findBookingsOfEntities, getAllEntities } from '../../api/teakEntity';
import { teakEntityStatus } from '../../constants/teakEntityStatus';
import { retrieveMemberPhoto } from '../../functions/memberPhotoUtils';


const slice = createSlice({
	name: 'entitiesBooking',
	initialState: {
		entitiesList: null,
		bookingsForBookedEntities: null,
		allAvailableEntitiesList: null,
		selectedAreaId: null,
		selectedArea: null,
		selectedStartTime: null,
		selectedEndTime: null,
		availableTeakFeatures: [],
		availableAreaList: [],
		selectedTeakType: null,
		maxBookingDate: DateTime.now().plus({ days: 14 }).toISO(),
		errorMessage: null,
		recurringBookingEnabled: false,
		recurringBooking: {
			until: DateTime.now().plus({ days: 1 }).toISO(),
			maxEndDate: DateTime.now().plus({ days: 1 }).toISO(),
			rrule: null,
			recurringConflicts: null,
		},
		repeatBookingObject: null,
		selectedEntity: null,
		attendeeList: null,
		bookOnBehalfColleague: null,
		bookOnBehalfColleagueSwitchOn: false,
		meetingName: null,
		editBookingObj: null,
		editBookingView: null,
		ui: {
			availableEntitiesLoading: false,
			showEntityList: false,
			listView: true,
			filterOpened: false,
			entitiesListLoading: false,
			areaAvailableEntitiesLoading: false,
		},
		onlineMeetingEnabled: false
	},
	reducers: {
		bookingsForBookedEntitiesUpdated: (entitiesBooking, action) => {
			entitiesBooking.bookingsForBookedEntities = action.payload;
		},
		entitiesListUpdated: (entitiesBooking, action) => {
			entitiesBooking.entitiesList = action.payload;
		},
		allAvailableEntitiesListUpdated: (entitiesBooking, action) => {
			entitiesBooking.allAvailableEntitiesList = action.payload;
		},
		availableEntitiesLoadingUpdated: (entitiesBooking, action) => {
			entitiesBooking.ui.availableEntitiesLoading = action.payload;
		},
		entitiesListLoadingReceived: (entitiesBooking, action) => {
			entitiesBooking.ui.entitiesListLoading = action.payload;
		},
		errorReceived: (entitiesBooking, action) => {
			entitiesBooking.errorMessage = action.payload.message;
		},
		areaChanged: (entitiesBooking, action) => {
			entitiesBooking.selectedArea = action.payload.areaObject;
			entitiesBooking.selectedAreaId = action.payload.areaId;
		},
		startTimeChanged: (entitiesBooking, action) => {
			entitiesBooking.selectedStartTime = action.payload;
		},
		endTimeChanged: (entitiesBooking, action) => {
			entitiesBooking.selectedEndTime = action.payload;
		},
		selectedEntityReceived: (entitiesBooking, action) => {
			entitiesBooking.selectedEntity = action.payload;
		},
		selectedTeakTypeReceived: (entitiesBooking, action) => {
			entitiesBooking.selectedTeakType = action.payload;
		},
		recurringBookingEnabledChanged: (entitiesBooking, action) => {
			entitiesBooking.recurringBookingEnabled = action.payload;
		},
		recurringRuleChanged: (entitiesBooking, action) => {
			entitiesBooking.recurringBooking.rrule = action.payload;
		},
		recurringEndTimeChanged: (entitiesBooking, action) => {
			entitiesBooking.recurringBooking.until = action.payload;
		},
		recurringMaxEndTimeChanged: (entitiesBooking, action) => {
			entitiesBooking.recurringBooking.maxEndDate = action.payload;
		},
		recurringConflictsReceived: (entitiesBooking, action) => {
			entitiesBooking.recurringBooking.recurringConflicts = action.payload;
		},
		maxBookingDateChanged: (entitiesBooking, action) => {
			entitiesBooking.maxBookingDate = action.payload;
		},
		parkingSpaceListOpenedChanged: (entitiesBooking, action) => {
			entitiesBooking.ui.parkingSpaceListOpened = action.payload;
		},
		filterOpenedReceived: (entitiesBooking, action) => {
			entitiesBooking.ui.filterOpened = action.payload;
		},
		repeatBookingObjectReceived: (entitiesBooking, action) => {
			entitiesBooking.repeatBookingObject = action.payload;
		},
		attendeeListChanged: (entitiesBooking, action) => {
			entitiesBooking.attendeeList = action.payload;
		},
		bookOnBehalfColleagueChanged: (entitiesBooking, action) => {
			entitiesBooking.bookOnBehalfColleague = action.payload;
		},
		bookOnBehalfColleagueSwitchOnChanged: (entitiesBooking, action) => {
			entitiesBooking.bookOnBehalfColleagueSwitchOn = action.payload;
		},
		//list view means the mode in which the entity list is shown -> listView = false we show the map view
		listViewUpdated: (entitiesBooking, action) => {
			entitiesBooking.ui.listView = action.payload;
		},
		areaAvailableEntitiesLoadingReceived: (entitiesBooking, action) => {
			entitiesBooking.ui.areaAvailableEntitiesLoading = action.payload;
		},
		availableTeakFeaturesUpdated: (entitiesBooking, action) => {
			entitiesBooking.availableTeakFeatures = action.payload;
		},
		availableAreaListUpdated: (entitiesBooking, action) => {
			entitiesBooking.availableAreaList = action.payload;
		},
		meetingNameUpdated: (entitiesBooking, action) => {
			entitiesBooking.meetingName = action.payload;
		},
		showEntityListChanged: (entitiesBooking, action) => {
			entitiesBooking.ui.showEntityList = action.payload;
		},
		editBookingObjChanged: (entitiesBooking, action) => {
			entitiesBooking.editBookingObj = action.payload;
		},
		editBookingViewChanged: (entitiesBooking, action) => {
			entitiesBooking.editBookingView = action.payload;
		},
		hybridMeetingStatusChanged: (entitiesBooking, action) => {
			entitiesBooking.onlineMeetingEnabled = action.payload
		}
	},
});

export const {
	editBookingObjChanged,
	entitiesListUpdated,
	allAvailableEntitiesListUpdated,
	entitiesListLoadingReceived,
	errorReceived,
	areaChanged,
	startTimeChanged,
	endTimeChanged,
	selectedEntityReceived,
	selectedTeakTypeReceived,
	recurringBookingEnabledChanged,
	recurringRuleChanged,
	recurringEndTimeChanged,
	recurringMaxEndTimeChanged,
	recurringConflictsReceived,
	maxBookingDateChanged,
	filterOpenedReceived,
	repeatBookingObjectReceived,
	attendeeListChanged,
	bookOnBehalfColleagueChanged,
	bookOnBehalfColleagueSwitchOnChanged,
	listViewUpdated,
	areaAvailableEntitiesLoadingReceived,
	availableTeakFeaturesUpdated,
	availableAreaListUpdated,
	meetingNameUpdated,
	showEntityListChanged,
	availableEntitiesLoadingUpdated,
	editBookingViewChanged,
	bookingsForBookedEntitiesUpdated,
	hybridMeetingStatusChanged,
} = slice.actions;

export default slice.reducer;

export const changeMeetingName = (meetingName) => async (dispatch, getState) => {
	if (meetingName === getState().entitiesBooking.meetingName) return;
	dispatch({ type: meetingNameUpdated.type, payload: meetingName });
};

export const changeShowEntityList = (show) => async (dispatch, getState) => {
	if (show === getState().entitiesBooking.ui.showEntityList) return;
	dispatch({ type: showEntityListChanged.type, payload: show });
};

export const changeEntityBookingStartTime = (newStartTime) => async (dispatch, getState) => {
	if (newStartTime === getState().entitiesBooking.selectedStartTime) return;
	const selectedTeakType = getState().entitiesBooking.selectedTeakType;
	const currentStartTime = getState().entitiesBooking.selectedStartTime;
	const currentEndTime = getState().entitiesBooking.selectedEndTime;
	if (DateTime.fromISO(newStartTime).ordinal > DateTime.fromISO(currentStartTime).ordinal) {
		if (selectedTeakType?.__t !== TEAK_TYPE_NAMES.ROOM) {
			const startTime = DateTime.fromISO(newStartTime).startOf('day').plus({ hours: 8 });
			dispatch({ type: startTimeChanged.type, payload: startTime.toISO() });
			dispatch({ type: endTimeChanged.type, payload: startTime.plus({ hours: 8 }).toISO() });
		} else {
			const newEndTime = newStartTime.substring(0, 10) + currentEndTime.substring(10);
			dispatch({ type: startTimeChanged.type, payload: newStartTime });
			dispatch({ type: endTimeChanged.type, payload: newEndTime });
		}
	} else {
		dispatch({ type: startTimeChanged.type, payload: newStartTime });
	}
};

export const changeEntityBookingEndTime = (endTime) => async (dispatch, getState) => {
	if (endTime === getState().entitiesBooking.selectedEndTime) return;

	dispatch({ type: endTimeChanged.type, payload: endTime });
};

export const changeEntityBookingTimes = (startTime, endTime) => async (dispatch, getState) => {
	dispatch({ type: startTimeChanged.type, payload: startTime });
	dispatch({ type: endTimeChanged.type, payload: endTime });
};

export const changeArea = (areaId, areaObject) => (dispatch, getState) => {
	if (areaId === getState().entitiesBooking.selectedAreaId) return;
	dispatch({ type: areaChanged.type, payload: { areaId, areaObject } });
};

export const addNewAvailableSelectedFeatures = (availableTeakFeatures) => async (dispatch, getState) => {
	dispatch({
		type: availableTeakFeaturesUpdated.type,
		payload: availableTeakFeatures,
	});
};

async function getAreasWithAvailableEntities(officeId, teakType, startTime, endTime, colleagueId, isOnlineMeeting) {
	const areaResponse = await getAreasInRange(
		officeId,
		teakType,
		{
			start: DateTime.fromISO(startTime).toUTC().toISO(),
			end: DateTime.fromISO(endTime).toUTC().toISO(),
		},
		colleagueId,
		isOnlineMeeting
	);
	return areaResponse?.data?.length > 0 ? sortAlphabetically(areaResponse.data.slice()) : [];
}

function getAllAvailableEntitiesFromAreaList(areaList) {
	let totalAvailableEntities = [];
	if (areaList.length > 0) {
		areaList.forEach((area) => {
			if (area.children.length > 0) {
				totalAvailableEntities.push(...getAllAvailableEntitiesFromAreaList(area.children));
			}
			totalAvailableEntities.push(...area?.areaTeakEntities);
		});
	}

	return totalAvailableEntities;
}

function getAreaObjectFromList(areaIdToBeFound, areaList) {
	let foundObject = null;
	if (!areaList || areaList?.length === 0) {
		return foundObject;
	}
	areaList.forEach((area) => {
		if (foundObject) return;
		if (area._id === areaIdToBeFound) {
			foundObject = area;
			return foundObject;
		} else if (area?.children?.length > 0) {
			foundObject = getAreaObjectFromList(areaIdToBeFound, area.children);
		}
	});
	return foundObject;
}

function getAvailableEntitiesForArea(selectedAreaId, areasWithAvailableEntities, availableEntitiesFromAreaListFull) {
	const newSelectedAreaObject = getAreaObjectFromList(selectedAreaId, areasWithAvailableEntities);
	const childrenAreaIds = newSelectedAreaObject?.children?.map((child) => child._id);
	const areaIdsToMatch = childrenAreaIds?.length > 0 ? [...childrenAreaIds, selectedAreaId] : [selectedAreaId];
	const entitiesMatchingSelectedArea =
		availableEntitiesFromAreaListFull?.length > 0
			? availableEntitiesFromAreaListFull.filter((entity) => areaIdsToMatch.includes(entity.area))
			: [];

	return { entities: entitiesMatchingSelectedArea, newSelectedAreaObject };
}

//add booking data for booked entities
const getBookingsForBookedEntities = (bookedEntities) => async (dispatch, getState) => {
	if (!bookedEntities.length) {
		dispatch({ type: bookingsForBookedEntitiesUpdated.type, payload: null });
		return;
	}

	const startTime = getState().entitiesBooking.selectedStartTime;
	const endTime = getState().entitiesBooking.selectedEndTime;
	const start = DateTime.fromISO(startTime).toUTC().toISO();
	const end = DateTime.fromISO(endTime).toUTC().toISO();

	try {
		const bookingData = await findBookingsOfEntities(
			bookedEntities.map((entity) => entity._id),
			start,
			end,
		);
		if (!bookingData.data?.length) return;
		const bookingsDataWithPhotos = [];
		for (const booking of bookingData.data) {
			const profilePhoto = await retrieveMemberPhoto(booking.member._id);

			bookingsDataWithPhotos.push({
				member: booking.member,
				teakEntity: booking.teakEntity,
				profilePhoto: profilePhoto,
			});
		}
		dispatch({ type: bookingsForBookedEntitiesUpdated.type, payload: bookingsDataWithPhotos });
	} catch (error) {
		return;
	}
};

//add entities inside the entity list for different area selection
export const addEntitiesForSelectedArea =
	(selectedAreaId, areasWithAvailableEntities, availableEntitiesFromAreaListFull) => async (dispatch, getState) => {
		dispatch({ type: availableEntitiesLoadingUpdated.type, payload: true });
		// if there is a selected area that we need to preselect we need to add the proper entities for the selected area in the entities List.
		if (selectedAreaId !== 'all_areas') {
			//load background image for selectedAreaId
			dispatch(loadBackgroundImage(selectedAreaId));
			if (getState().entitiesBooking.ui.listView === true) dispatch(updateListView(false));
			const { entities, newSelectedAreaObject } = getAvailableEntitiesForArea(
				selectedAreaId,
				areasWithAvailableEntities,
				availableEntitiesFromAreaListFull,
			);
			dispatch({ type: areaChanged.type, payload: { areaId: selectedAreaId, areaObject: newSelectedAreaObject } });
			const bookedEntities = entities.filter((entity) => (entity.status === teakEntityStatus.booked.status || entity.status === teakEntityStatus.entityRestricted.status));
			if (bookedEntities) dispatch(getBookingsForBookedEntities(bookedEntities));
			dispatch({ type: entitiesListUpdated.type, payload: entities });
		} else {
			dispatch({ type: areaChanged.type, payload: { areaId: 'all_areas', areaObject: { _id: 'all_areas', name: 'All Areas' } } });
			dispatch(updateListView(true));
			dispatch({ type: entitiesListUpdated.type, payload: availableEntitiesFromAreaListFull });
		}
		dispatch({ type: availableEntitiesLoadingUpdated.type, payload: false });
	};

//changes area during the booking process, this will change area -> update the list of entities
export const changeAreaForBookingProcess = (selectedAreaId, selectedAreaObject) => async (dispatch, getState) => {
	dispatch(changeArea(selectedAreaId, selectedAreaObject));
	const areasWithAvailableEntities = getState().entitiesBooking.availableAreaList;
	const availableEntitiesFromAreaListFull = getState().entitiesBooking.allAvailableEntitiesList;
	dispatch(addEntitiesForSelectedArea(selectedAreaId, areasWithAvailableEntities, availableEntitiesFromAreaListFull));
};

//gets the areas with available entities from BE and adds them to the store.
export const addAvailableAreasWithEntities = (callback, bookingData) => async (dispatch, getState) => {
	dispatch({ type: areaAvailableEntitiesLoadingReceived.type, payload: true });
	const entitiesBookingSlice = getState().entitiesBooking;
	const authDataSlice = getState().auth.data;
	const { selectedStartTime, selectedEndTime, selectedTeakType, bookOnBehalfColleague } = bookingData ? bookingData : entitiesBookingSlice;
	const { areas, featureTranslations, userData, teakFeatures, selectedOffice } = authDataSlice;
	const colleague = bookOnBehalfColleague ? bookOnBehalfColleague : userData;
	const isHybridChecked = entitiesBookingSlice.onlineMeetingEnabled;

	const areasWithAvailableEntities = await getAreasWithAvailableEntities(
		selectedOffice._id,
		selectedTeakType._id,
		selectedStartTime,
		selectedEndTime,
		colleague._id,
		isHybridChecked
	);
	dispatch({
		type: availableAreaListUpdated.type,
		payload: areasWithAvailableEntities,
	});

	const availableEntitiesFromAreaList = getAllAvailableEntitiesFromAreaList(areasWithAvailableEntities);
	if (!availableEntitiesFromAreaList || availableEntitiesFromAreaList?.length === 0) {
		dispatch({
			type: allAvailableEntitiesListUpdated.type,
			payload: [],
		});
		if (callback) callback(areasWithAvailableEntities, []);
	} else {
		const availableEntitiesFromAreaListFull = getTeakEntitiesFull(
			areas,
			featureTranslations,
			userData,
			teakFeatures,
			availableEntitiesFromAreaList,
		); // populate teakEntities with featureNames and areaName

		dispatch({
			type: allAvailableEntitiesListUpdated.type,
			payload: availableEntitiesFromAreaListFull,
		});
		if (callback) callback(areasWithAvailableEntities, availableEntitiesFromAreaListFull);
	}
	dispatch({ type: areaAvailableEntitiesLoadingReceived.type, payload: false });
};

// get areas with available entities and after add the correct entities based on the selected area
export const showResultsBookingProcess = () => async (dispatch, getState) => {
	const entitiesBookingSlice = getState().entitiesBooking;
	const { selectedAreaId } = entitiesBookingSlice;

	dispatch(
		addAvailableAreasWithEntities((areasWithAvailableEntities, availableEntitiesFromAreaListFull) => {
			dispatch(addEntitiesForSelectedArea(selectedAreaId, areasWithAvailableEntities, availableEntitiesFromAreaListFull));
		}),
	);
};

//show booking on map -> the booking already has times and an entity
export const showBookingOnMap = (booking, entity, bottomCardType) => async (dispatch, getState) => {
	//show entity list and enable the map
	dispatch(changeShowEntityList(true));
	dispatch(updateListView(false));
	const bookingData = {
		selectedStartTime: booking.start.time,
		selectedEndTime: booking.end.time,
		selectedTeakType: {
			_id: entity.teakType,
		},
		bookOnBehalfColleague: null,
	};
	let profilePhoto;
	if (booking?.profilePhoto) {
		profilePhoto = booking.profilePhoto;
	} else {
		const memberPhotos = getState().auth.data.memberPhotos;
		profilePhoto = await retrieveMemberPhoto(booking?.member?._id ?? booking?.member, memberPhotos, dispatch);
	}
	dispatch(changeEntityBookingTimes(booking.start.time, booking.end.time));
	dispatch(changeSelectedTeakType(null, entity.teakType, null));
	// add areas data for the times of the booking and show entity on map
	dispatch(
		addAvailableAreasWithEntities((areasWithAvailableEntities, availableEntitiesFromAreaListFull) => {
			dispatch(
				showEntityOnMap(entity, bottomCardType, areasWithAvailableEntities, availableEntitiesFromAreaListFull, { ...booking, profilePhoto }),
			);
		}, bookingData),
	);
};

//show entity on map
export const showEntityOnMap =
	(entity, bottomCardType, updatedAreasWithAvailableEntities, updatedAvailableEntitiesFromAreaListFull, booking) => async (dispatch, getState) => {
		const areasFromStore = getState().auth.data.areas;
		const selectedArea = getAreaOfEntity();
		const areasWithAvailableEntities = updatedAreasWithAvailableEntities
			? updatedAreasWithAvailableEntities
			: getState().entitiesBooking.availableAreaList;
		const availableEntitiesFromAreaListFull = updatedAvailableEntitiesFromAreaListFull
			? updatedAvailableEntitiesFromAreaListFull
			: getState().entitiesBooking.allAvailableEntitiesList;
		//show entity list and enable the map
		dispatch(addEntitiesForSelectedArea(selectedArea._id, areasWithAvailableEntities, availableEntitiesFromAreaListFull));
		dispatch(updateHighlightEntityData(booking ? { entity, booking } : { entity }));

		dispatch(enableBottomCard(bottomCardType));
		dispatch(updateBottomCardData(booking ? { entity, booking } : { entity }));

		function getAreaOfEntity() {
			let selectedArea = areasFromStore.find((area) => area._id === entity?.area || area._id === entity?.area?._id);
			//if selectedArea is a zone find the parent and use that to highlight on map. AreaZones dont have maps
			if (selectedArea?.__t === 'AreaZone') {
				let parentArea = areasFromStore.find((area) => area._id === selectedArea.parent);
				if (parentArea) selectedArea = parentArea;
			}
			return selectedArea;
		}
	};

export const updateSelectedFeatures = (_id) => async (dispatch, getState) => {
	const currentSelectedFeatures = getState().entitiesBooking.availableTeakFeatures;
	const index = currentSelectedFeatures.findIndex((el) => el._id === _id);
	const previousValue = currentSelectedFeatures[index].value;
	let arrayCopy = currentSelectedFeatures.slice();

	arrayCopy[index] = {
		...currentSelectedFeatures[index],
		value: previousValue ? !previousValue : true,
	};

	dispatch({ type: availableTeakFeaturesUpdated.type, payload: arrayCopy });
};

export const addArrayOfSelectedFeatures = (selectedFeatures) => async (dispatch, getState) => {
	const currentSelectedFeatures = getState().entitiesBooking.availableTeakFeatures;
	let newSelectedFeaturesArray = [];
	for (let i = 0; i < currentSelectedFeatures?.length; i++) {
		let feature = currentSelectedFeatures[i];
		newSelectedFeaturesArray.push({ ...feature, value: selectedFeatures.includes(feature._id) });
	}
	dispatch({ type: availableTeakFeaturesUpdated.type, payload: newSelectedFeaturesArray });
};

export const changeSelectedTeakType = (teakType, teakTypeId, teakTypeName, timesAlreadySet) => (dispatch, getState) => {
	if (!teakType && !teakTypeId && !teakTypeName) {
		dispatch({ type: selectedTeakTypeReceived.type, payload: null });
		return;
	}
	function getTeakTypeObj() {
		const selectedOffice = getState().auth.data.selectedOffice;
		const teakTypeArray = getState().auth.data.teakTypeArray;
		const teakTypeDataForOffice = teakTypeArray.find((teakTypeForOffice) => teakTypeForOffice.office === selectedOffice.id && teakTypeForOffice);
		return teakTypeDataForOffice.teakTypes.find((teakType) => teakType._id === teakTypeId || teakType.__t === teakTypeName);
	}
	const selectedTeakTypeObj = teakType ? teakType : getTeakTypeObj();

	if (!selectedTeakTypeObj) return;
	const selectedStartTime = getState().entitiesBooking.selectedStartTime;

	if (!timesAlreadySet) {
		let newEndTime;
		const startTime = selectedStartTime || DateTime.now();
		// if selected teak type is room -> change the time duration to be 1 hr
		if (selectedTeakTypeObj?.__t === TEAK_TYPE_NAMES.ROOM) {
			newEndTime = roundTimeQuarterHour(DateTime.fromISO(startTime).plus({ hours: 1 }).toISO());
		} else {
			newEndTime = roundTimeQuarterHour(DateTime.fromISO(startTime).plus({ hours: 8 }).toISO());
		}
		dispatch(changeEntityBookingEndTime(newEndTime));
	}

	const teakFeaturesFromStore = getState().auth.data.teakFeatures;
	const teakFeaturesForSelectedBookingMethod = teakFeaturesFromStore.filter((feature) => feature.teakType === selectedTeakTypeObj?._id);
	dispatch(
		changeMaxBookingDate(
			DateTime.now()
				.plus({
					milliseconds: selectedTeakTypeObj?.options?.bookings?.maxInAdvance?.timeAfterNow,
				})
				.toISO(),
		),
	);
	dispatch({
		type: availableTeakFeaturesUpdated.type,
		payload: teakFeaturesForSelectedBookingMethod,
	});
	dispatch({ type: selectedTeakTypeReceived.type, payload: selectedTeakTypeObj });
};

export const updateSelectedEntity = (entity) => (dispatch, getState) => {
	if (entity === getState().entitiesBooking.selectedEntity) return;
	dispatch({ type: selectedEntityReceived.type, payload: entity });
};
export const enableFilter = (show) => (dispatch, getState) => {
	if (show === getState().entitiesBooking.ui.filterOpened) return;
	dispatch({ type: filterOpenedReceived.type, payload: show });
};

export const enableRecurringBooking = (enable) => (dispatch, getState) => {
	if (enable === getState().entitiesBooking.recurringBookingEnabled) return;

	if (!enable) {
		dispatch({ type: recurringRuleChanged.type, payload: null });
	}
	dispatch({ type: recurringBookingEnabledChanged.type, payload: enable });
};

export const changeRecurringMaxEndTime = (endDate) => (dispatch, getState) => {
	dispatch({ type: recurringMaxEndTimeChanged.type, payload: endDate });
};

export const changeRecurringEndTime = (endDate) => (dispatch, getState) => {
	dispatch({ type: recurringEndTimeChanged.type, payload: endDate });
};

export const addRecurringConflicts = (conflictsList) => (dispatch, getState) => {
	dispatch({
		type: recurringConflictsReceived.type,
		payload: conflictsList,
	});
};

export const changeRRule = (interval, byweekday) => (dispatch, getState) => {
	const rrule = new RRule({
		freq: RRule.WEEKLY,
		interval: interval,
		byweekday: byweekday,
		dtstart: new Date(getState().entitiesBooking.selectedStartTime),
		until: new Date(getState().entitiesBooking.recurringBooking.until),
	});

	dispatch({ type: recurringRuleChanged.type, payload: rrule.toString() });
};

export const deleteRRule = () => (dispatch, getState) => {
	dispatch({ type: recurringRuleChanged.type, payload: null });
	dispatch({ type: recurringBookingEnabledChanged.type, payload: false });
};

export const changeMaxBookingDate = (newDate) => (dispatch, getState) => {
	if (newDate === getState().entitiesBooking.maxBookingDate) return;
	dispatch({ type: maxBookingDateChanged.type, payload: newDate });
};

export const addRepeatBookingObject = (repeatBookingObject) => (dispatch, getState) => {
	if (getState().entitiesBooking.repeatBookingObject === repeatBookingObject) return;

	dispatch({
		type: repeatBookingObjectReceived.type,
		payload: repeatBookingObject,
	});
};

export const updateHybridVirtual = (isHybridMeetingEnabled) => (dispatch, getState) => {
	if (getState().entitiesBooking.onlineMeeting === isHybridMeetingEnabled) return;

	dispatch({ type: hybridMeetingStatusChanged.type, payload: isHybridMeetingEnabled });
};

export const updateAttendeeList = (list) => (dispatch, getState) => {
	if (getState().entitiesBooking.attendeeList === list) return;

	dispatch({ type: attendeeListChanged.type, payload: list });

};

export const updateBookOnBehalfColleague = (colleague) => (dispatch, getState) => {
	if (getState().entitiesBooking.bookOnBehalfColleague === colleague) return;

	dispatch({ type: bookOnBehalfColleagueChanged.type, payload: colleague });
};

export const updateBookOnBehalfColleagueSwitchOn = (state) => (dispatch, getState) => {
	if (getState().entitiesBooking.attendeeList === state) return;
	dispatch({ type: bookOnBehalfColleagueSwitchOnChanged.type, payload: state });
};

export const removeAttendeeFromList = (attendee) => (dispatch, getState) => {
	let currentList = getState().entitiesBooking.attendeeList.slice();
	const index = currentList.findIndex((item) => item.id === attendee.id);
	if (index > -1) {
		currentList.splice(index, 1);
		dispatch({ type: attendeeListChanged.type, payload: currentList });
	}
};


export const loadAllEntities = (areaId, teakType, callback) => async (dispatch, getState) => {
	dispatch({ type: entitiesListLoadingReceived.type, payload: true });

	try {
		const areas = getState().auth.data.areas;
		const teakFeatures = getState().auth.data.teakFeatures;
		const selectedAreaObject = areas.find((area) => area._id === areaId);
		const officeId = getState().auth.data.selectedOffice._id;
		const userData = getState().auth.data.userData;
		const featureTranslations = getState().auth.data.featureTranslations;
		const manyTeakEntitiesResponse = await getAllEntities(areaId, officeId, teakType?._id);
		const teakEntitiesFull = getTeakEntitiesFull(areas, featureTranslations, userData, teakFeatures, manyTeakEntitiesResponse.data); // populate teakEntities with featureNames and areaName
		let entitiesListWithZones = [];

		//check if area map has zones and add in list the entities of those zones.
		if (selectedAreaObject.children?.length > 0) {
			let entitiesList = teakEntitiesFull.slice();
			await async.each(selectedAreaObject.children, async (areaZone, callback) => {
				try {
					let areaChildObj = areas.find((area) => area._id === areaZone._id);
					if (!areaChildObj) {
						if (callback) return callback();
						return;
					}
					let teakEntities = await getAllEntities(areaZone._id, officeId, teakType?._id);
					let teakEntitiesFull = getTeakEntitiesFull(areas, featureTranslations, userData, teakFeatures, teakEntities.data);

					entitiesList = [...entitiesList, ...teakEntitiesFull];
				} catch (error) {
					console.log(error);
				}

				if (callback) return callback();
				return;
			});
			entitiesListWithZones = entitiesList;
		} else {
			entitiesListWithZones = teakEntitiesFull;
		}
		dispatch({
			type: entitiesListUpdated.type,
			payload: entitiesListWithZones,
		});

		dispatch({ type: entitiesListLoadingReceived.type, payload: false });
		if (callback) callback();
	} catch (error) {
		console.log(error);
		dispatch({ type: errorReceived.type, payload: error.message });
		dispatch({ type: entitiesListUpdated.type, payload: [] });
		dispatch({ type: entitiesListLoadingReceived.type, payload: false });
	}
};

export const updateListView = (newValue) => (dispatch, getState) => {
	if (newValue === getState().entitiesBooking.ui.listView) return;

	dispatch({ type: listViewUpdated.type, payload: newValue });
};

//clears previous data if there is any and update the time.
export const clearBookingProcessData = () => (dispatch, getState) => {
	const authData = getState().auth.data;
	const selectedOfficeObject = authData.selectedOffice;
	const startTime = roundTimeQuarterHour(DateTime.now().plus({ minutes: 10 }).toISO());
	const endTime = roundTimeQuarterHour(DateTime.now().plus({ hours: 8, minutes: 10 }).toISO());

	dispatch(
		changeEntityBookingTimes(
			DateTime.fromISO(startTime).setZone(selectedOfficeObject.timezone).toISO(),
			DateTime.fromISO(endTime).setZone(selectedOfficeObject.timezone).toISO(),
		),
	);
	dispatch(changeShowEntityList(false));
	dispatch(changeMeetingName(null));
	dispatch(updateAttendeeList(null));
	dispatch(updateBookOnBehalfColleague());
	dispatch(updateBookOnBehalfColleagueSwitchOn(false));
	dispatch(changeSelectedTeakType(null));

	// disable any highlighting
	dispatch(setOfficeElementsToHighlight(null));
};

export const updateEditBookingObj = (booking) => (dispatch, getState) => {
	dispatch({ type: editBookingObjChanged.type, payload: booking });
};

export const updateEditBookingView = (newView) => (dispatch, getState) => {
	dispatch({ type: editBookingViewChanged.type, payload: newView });
};
//selectors
