class ThemeEngine {

	mainColor = '5236AB';

	constructor() {
		this.refreshThemeColor();
		this.listenColorChangeEvent();
	}

	// Listen for color change events
	// to call event from browser console
	// window.postMessage({type: 'theme-color', color: '#FF0000'});
	listenColorChangeEvent() {
		window.addEventListener('message', (event) => {
			if (event.data && event.data.type === 'theme-color') {
				this.setThemeColor(event.data.color, false);
			}
		});
	}

	refreshThemeColor() {
		// Get the URL params
		const urlParams = new URLSearchParams(window.location.search);
		const themeColor = urlParams.get('theme');

		if (themeColor) {
			this.mainColor = themeColor;
			this.setThemeColor(`#${this.mainColor}`, false);
		} else {
			this.mainColor = localStorage.getItem('theme-color') || '5236AB';
			this.setThemeColor(`#${this.mainColor}`, false);
		}
	}

	updateColorMainVariables(mainColor, saveToLocalStorage = true) {
		document.body.style.setProperty('--color-main', mainColor);
		document.body.style.setProperty('--color-main-0', this.lightenDarkenColor(mainColor, -60));
		document.body.style.setProperty('--color-main-10', this.lightenDarkenColor(mainColor, -50));
		document.body.style.setProperty('--color-main-20', this.lightenDarkenColor(mainColor, -40));
		document.body.style.setProperty('--color-main-30', this.lightenDarkenColor(mainColor, -30));
		document.body.style.setProperty('--color-main-40', this.lightenDarkenColor(mainColor, -20));
		document.body.style.setProperty('--color-main-50', this.lightenDarkenColor(mainColor, -10));
		document.body.style.setProperty('--color-main-60', this.lightenColor(mainColor, 65));
		document.body.style.setProperty('--color-main-70', this.lightenColor(mainColor, 55));
		document.body.style.setProperty('--color-main-80', this.lightenColor(mainColor, 45));
		document.body.style.setProperty('--color-main-90', this.lightenColor(mainColor, 35));
		document.body.style.setProperty('--color-main-95', this.lightenColor(mainColor, 30));

		// Save the new color to localStorage
		if (saveToLocalStorage) {
			localStorage.setItem('theme-color', mainColor.replace('#', ''));
		}
	}

	setThemeColor(hexColor, saveToLocalStorage = true) {
		this.mainColor = hexColor;
		this.updateColorMainVariables(this.mainColor, saveToLocalStorage);
	}

	lightenDarkenColor(col, amt) {
		let usePound = false;

		if (col[0] === "#") {
			col = col.slice(1);
			usePound = true;
		}

		let num = parseInt(col, 16);
		let r = (num >> 16) + amt;
		let g = ((num >> 8) & 0x00FF) + amt;
		let b = (num & 0x0000FF) + amt;

		if (r > 255) r = 255;
		else if (r < 0) r = 0;

		if (g > 255) g = 255;
		else if (g < 0) g = 0;

		if (b > 255) b = 255;
		else if (b < 0) b = 0;

		return (usePound ? "#" : "") + (r << 16 | g << 8 | b).toString(16).padStart(6, '0');
	}

	lightenColor(color, percent) {
		const rgbaColor = this.transformHexIntoRgba(color, percent);
		return this.blendWithWhite(rgbaColor);
		// return rgbaColor;
	}

	transformHexIntoRgba(color, opacity) {
		const hex = color.replace('#', '');
		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);

		return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
	}

	/**
	 * Blend the color with white in the background
	 * @param rgbaColor
	 * @private
	 */
	blendWithWhite(rgbaColor) {
		// Parse the rgbaColor string
		const rgbaMatch = rgbaColor.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/);

		if (!rgbaMatch) {
			console.error("Invalid RGBA format");
			return;
		}

		// Extract the RGBA values
		const r = parseInt(rgbaMatch[1]);
		const g = parseInt(rgbaMatch[2]);
		const b = parseInt(rgbaMatch[3]);
		const a = parseFloat(rgbaMatch[4]);

		// Background color is white (255, 255, 255)
		const backgroundR = 255;
		const backgroundG = 255;
		const backgroundB = 255;

		// Formula to blend the foreground color with the background
		const blendedR = Math.round((1 - a) * backgroundR + a * r);
		const blendedG = Math.round((1 - a) * backgroundG + a * g);
		const blendedB = Math.round((1 - a) * backgroundB + a * b);

		// Convert RGB to Hex
		const toHex = (channel) => {
			const hex = channel.toString(16);
			return hex.length === 1 ? '0' + hex : hex; // Ensure two digits
		};

		// Combine and return the hex value
		return `#${toHex(blendedR)}${toHex(blendedG)}${toHex(blendedB)}`;
	}
}

const themeManagerInstance = new ThemeEngine();
export default themeManagerInstance;
