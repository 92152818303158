import BarLoader from 'react-spinners/BarLoader';

import {useTranslation} from 'react-i18next';

import {useSelector} from 'react-redux';
import colorBasedOnTheme from '../../functions/colorBasedOnTheme';

function LoadingScreen() {
	const {t} = useTranslation();
	const colors = colorBasedOnTheme();
	const loadingMessageKey = useSelector((state) => state.auth.ui.loadingMessageKey);
	const majorErrorMessage = useSelector((state) => state.auth.ui.majorErrorMessage);

	return (
		<div className="loading-page">
			<div className="loading-logo-wrapper">
				<img className="logo" alt="logo" src={process.env.PUBLIC_URL + '/images/logo_text.svg'} />
				{majorErrorMessage ? (
					<>
						<p>{t(majorErrorMessage)}</p>
						<p>{t('errorMessages.general')}</p>
					</>
				) : (
					<>
						<BarLoader
							css={{borderRadius: '5px'}}
							width={150}
							height={10}
							size={200}
							color='var(--color-main)'
							loading={true}
							speedMultiplier={1}
						/>
						<p>{t(loadingMessageKey)}</p>
					</>
				)}
			</div>
			<Copyright />
		</div>
	);
}

export const Copyright = ({additionalElement, showCGILogo}) => {
	const {t} = useTranslation();
	return (
		<div className="copyright-section">
			{additionalElement}
			{showCGILogo && (
				<div className="cgi-logo-wrapper">
					<p>{t('loginPage.infoText.intro.solutionBy')}</p>
					<img className="cgi-logo" alt="logo" src={process.env.PUBLIC_URL + '/images/cgi_logo.svg'} />
				</div>
			)}
			<p>{t('loginPage.infoText.intro.copyright')}</p>
		</div>
	);
};

export default LoadingScreen;
