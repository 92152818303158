import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { colleaguePlaceholderPhoto } from '../../../constants/images';
import { retrieveMemberPhoto } from '../../../functions/memberPhotoUtils';

/**
 *
 * @param {*} props.data = colleague data to be displayed {name: {full, first, last}, email, _id}
 * @param {*} props.checkboxClicked = callback to handle checkbox click
 * @param {*} props.enableCheck = boolean flag to show or not the checkbox
 * @param {*} props.removeItem = callback to handle X button click
 * @param {*} props.invitationResponse = attendees' response to the organizer (accept, decline, tentative, etc.)
 * @param {*} props.hasRoomTypeMSOutlook = boolean flag to indicate if the meeting is an outlook meeting or not
 * @param {*} props.index = number that indicates the handled items index.
 * @param {*} props.listLength = length of the handled iterated items
 * @returns
 */
const ColleagueItem = ({
	data,
	checkboxClicked,
	enableCheck,
	removeItem,
	colleagueStatus,
	loadingColleagueStatus,
	connection,
	checkedIn,
	invitationResponse,
	hasRoomTypeMSOutlook,
	index,
	listLength,
	showRemoveButton,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const name = data?.name?.full ?? `${data.name?.first ?? ''} ${data.name?.last ?? ''}`;
	const email = data?.email;
	const id = data?._id ? data._id : data.id;
	const memberPhotos = useSelector((state) => state.auth.data.memberPhotos);
	const checkedList = useSelector((state) => state.settings.colleagueFind.checkedList);
	const checked = checkedList ? checkedList?.some((item) => item?.id === id || item?.member?._id === id) : false;
	const [profilePhoto, setProfilePhoto] = useState(colleaguePlaceholderPhoto);

	useEffect(() => {
		const loadPhoto = async () => {
			try {
				const memberPhoto = await retrieveMemberPhoto(id, memberPhotos, dispatch);
				setProfilePhoto(memberPhoto);
			} catch (error) {
				console.error('Error loading profile photo:', error);
				setProfilePhoto(colleaguePlaceholderPhoto);
			}
		};

		loadPhoto();
	}, [id, memberPhotos, dispatch]);

	return (
		<>
			<div
				className="colleague-item-wrapper"
				onClick={() => {
					checkboxClicked && checkboxClicked(data);
				}}>
				{enableCheck && (
					<div className="checkbox-wrapper">
						<input
							className="input-checkbox"
							type="checkbox"
							id={'checked-item-' + data.id}
							checked={checked}
							onChange={(e) => {
								e.preventDefault();
								checkboxClicked(data);
							}}
						/>
						<label className="input-checkbox-label" htmlFor={'checked-item-' + data.id} />
					</div>
				)}
				<div className="photo-wrapper">
					{profilePhoto ? (
						<img src={profilePhoto} alt={'member'} />
					) : (
						<div className="ph-item">
							<div className="ph-row">
								<div className="ph-col-12"></div>
							</div>
						</div>
					)}
				</div>
				<div className="info-content">
					{name ? (
						<div className="name-status-wrapper">
							<p className="name">{name}</p>

							{loadingColleagueStatus ? (
								<div className="status-loading">
									<div className="ph-item">
										<div className="ph-row">
											<div className="ph-col-12"></div>
										</div>
									</div>
								</div>
							) : (
								<>
									{colleagueStatus && (
										<div className={`status status-${colleagueStatus?.isExternallyAvailable ? 'available' : 'busy'}`}>
											<p>{t(colleagueStatus?.isExternallyAvailable ? 'mapView.legend.available' : 'common.busy')}</p>
										</div>
									)}
								</>
							)}

							{connection && (
								<div className={`status status-available`}>
									<p>{connection?.event?.eventName ? connection?.event?.eventName : connection.event?.eventId?.name}</p>
								</div>
							)}
						</div>
					) : (
						<p className="name">{t('common.noName')}</p>
					)}
					{email ? <p className="email">{email}</p> : null}
				</div>
				{!enableCheck && removeItem && showRemoveButton &&(
					<div className="remove-action" onClick={(e) => removeItem(e, data)}>
						<span className="close">&times;</span>
					</div>
				)}
				{checkedIn && (
					<div className="checkedIn status">
						<p>{t('bookingStatus.checkedIn')}</p>
					</div>
				)}
				{invitationResponse && hasRoomTypeMSOutlook && (
					<div className="external status">
						<div className="container">
							<p className={`response-${invitationResponse}`}>{t('visitorManagement.attendance.' + invitationResponse)}</p>
						</div>
					</div>
				)}
			</div >
			{index !== listLength - 1 ? <hr className="hr-colleague-item-bottom"></hr> : <></>
			}
		</>
	);
};

export default ColleagueItem;
