import { useTranslation } from 'react-i18next';
import SecondaryHeader from '../Common/Header/SecondaryHeader';
import { useDispatch, useSelector } from 'react-redux';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import { limit } from '../../constants/limit';
import { Typography } from '../Common/Typography';
import { useEffect, useRef, useState } from 'react';
import { selectStyleOptions } from '../../constants/selectStyleOptions';
import Select from 'react-select';
import { Textarea } from '../Common/Textareas/Textarea';
import DateTimePicker from '../Common/DateTimePicker/DateTimePicker';
import moment from 'moment';
import { DateTime } from 'luxon';
import Button from '../Common/Button';
import { useLocation, useHistory } from 'react-router-dom';
import { addTimeRecord, updateTimeRecord } from '../../api/timeTracker';
import { enableCard, updateConfirmationCard, updateErrorCard, updateSuccessCard } from '../../store/cards';
import { CARD } from '../../constants/cards';
import { confirmActionsMap } from '../Cards/Common/ConfirmAction/confirmActions';
import { CEDAR_TYPES } from '../Dashboard/TimeTracker/TimeTracker';
import Switcher from '../Common/Switcher';
function TimeTrackerSettings({ editActivity = false, newActivity = false }) {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const navigate = useCustomNavigation();
	const [cssForHiddenDiv, setCssForHiddenDiv] = useState('-hidden'); //the div is used to make the content scrollable when the virtual keyboard is on
	const authData = useSelector((state) => state.auth.data);
	const { selectedOffice } = authData;
	const timezone = selectedOffice?.timezone;
	const officeId = selectedOffice?._id;
	const [timeError, setTimeError] = useState(null);
	const currentTime = DateTime.now().setZone(timezone);
	const currentTimeInISO = currentTime.toISO();
	const [timeRecord, setTimeRecord] = useState(location.state?.actionSheetData);
	const [notes, setNotes] = useState(timeRecord?.notes);
	const [loading, setLoading] = useState(false);
	const contentRef = useRef();
	const changeRequests = useSelector((state) => state.cedar.changeRequests);
	const workingHours = useSelector((state) => state.auth?.data?.userData?.biro?.workingHours) ?? {};
	const workOptions = [
		{
			value: CEDAR_TYPES.work,
			label: t('timeTracker.options.work'),
		},
		{
			value: CEDAR_TYPES.pause,
			label: t('timeTracker.options.pause'),
		},
		{
			value: CEDAR_TYPES.vacation,
			label: t('timeTracker.options.vacation'),
		},
		{
			value: CEDAR_TYPES.sickLeave,
			label: t('timeTracker.options.sickLeave'),
		},
	];
	//Object order is strict, and should not be changed.
	const [daysOptions, setDaysOptions] = useState([
		{
			value: 'partDay',
			label: t('timeTracker.options.partDay'),
		},
		{
			value: 'fullDay',
			label: t('timeTracker.options.fullDay'),
		},

	]);
	const [day, setDay] = useState(daysOptions[0]);
	const [activityName, setActivityName] = useState(workOptions.find((option) => option.value === timeRecord?.name) || workOptions[0]);
	const [sickNoteSwitcher, setSickNoteSwitcher] = useState(false);
	const [disableFuture, setDisableFuture] = useState(false);
	const [futureAllowedTypes, setFutureAllowedTypes] = useState([CEDAR_TYPES.work, CEDAR_TYPES.pause]);
	
	const isWorkOrPause = activityName?.value === CEDAR_TYPES.work || activityName?.value === CEDAR_TYPES.pause;
	const isVacationPartDay = activityName?.value === CEDAR_TYPES.vacation && day?.value === 'partDay';
	const isSickLeaveWithoutSwitcher = activityName?.value === CEDAR_TYPES.sickLeave && !sickNoteSwitcher;
	const disabledTimes = (!isWorkOrPause && !isVacationPartDay) || isSickLeaveWithoutSwitcher;

	useEffect(() => {
		if (activityName?.value === CEDAR_TYPES.work || activityName?.value === CEDAR_TYPES.pause) {
			setDay(daysOptions[1]);
		}
		if (activityName?.value === CEDAR_TYPES.sickLeave) {
			setDaysOptions([
				{
					value: 'fullDay',
					label: t('timeTracker.options.fullDay'),
				},
			]);
			setDay({
				value: 'fullDay',
				label: t('timeTracker.options.fullDay'),
			});
		} else {
			setDaysOptions([
				{
					value: 'partDay',
					label: t('timeTracker.options.partDay'),
				},
				{
					value: 'fullDay',
					label: t('timeTracker.options.fullDay'),
				},
			]);
		}
	}, [activityName]);

	useEffect(() => {
		if (timeRecord && newActivity) {
			const timeRecordDate = DateTime.fromISO(timeRecord?.end?.time).startOf('day');

			const updatedTimeRecord = timeRecordDate
				.set({
					hour: currentTime.hour,
					minute: currentTime.minute,
				})
				.toISO();

			setTimeRecord({
				start: { time: updatedTimeRecord },
				end: { time: updatedTimeRecord },
			});

			if (activityName?.value === CEDAR_TYPES.sickLeave) {
				if (sickNoteSwitcher) {
					setFutureAllowedTypes([CEDAR_TYPES.work, CEDAR_TYPES.pause, CEDAR_TYPES.sickLeave]);
				} else {
					setFutureAllowedTypes([CEDAR_TYPES.work, CEDAR_TYPES.pause]);
				}
				setDisableFuture(shouldDisableFuture(activityName?.value));
			}
		}
	}, []);

	useEffect(() => {
		const startTimeObj = DateTime.fromISO(timeRecord?.start?.time).setZone(timezone);
		const endTimeObj = DateTime.fromISO(timeRecord?.end?.time).setZone(timezone);

		if (!timeRecord?.start?.time || !timeRecord?.end?.time) return;
		if (disableFuture && endTimeObj > DateTime.now()) {
			setTimeError(<p className="error-text">{t('errorMessages.endInTheFuture')}</p>);
		} else if (startTimeObj > endTimeObj) {
			setTimeError(<p className="error-text">{t('errorMessages.startAfterEnd')}</p>);
		} else if (timeError) {
			setTimeError(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeRecord?.start?.time, timeRecord?.end?.time, activityName]);

	useEffect(() => {
		updateDisableFuture();
	}, [activityName, sickNoteSwitcher, futureAllowedTypes]);

	const calculateSubmittedSickLeaveAmount = (changeRequests) => {
		const startTime = DateTime.fromISO(timeRecord?.start?.time).setZone(timezone);
		const yesterdayStart = startTime.minus({ days: 1 }).startOf('day');
		const yesterdayEnd = startTime.startOf('day').minus({ seconds: 1 });
	
		if (!changeRequests) return 0;
	
		return changeRequests?.filter(e => 
			e.status === 'pending' && 
			e.update?.name === 'sickLeave' &&
			DateTime.fromISO(e.update?.start?.time).setZone(timezone) >= yesterdayStart &&
			DateTime.fromISO(e.update?.start?.time).setZone(timezone) <= yesterdayEnd
		)?.length;
		
	};
	const backButtonHandler = () => {
		navigate.goBack();
	};
	document.addEventListener('focusout', () => {
		setCssForHiddenDiv('-hidden');
	});

	const handleFocus = (elId) => {
		setCssForHiddenDiv('');
		setTimeout(() => {
			const element = document.getElementById(elId);
			if (element && contentRef.current) {
				console.log(element.offsetTop);
				contentRef.current.scrollTop = element.offsetTop - 100; // -100 because the header is not scrollable
			}
		}, 100);
	};
	const changeStartTime = (newStartTime) => {
		const newStartTimeObj = DateTime.fromISO(newStartTime).setZone(timezone).toISO();
		setTimeRecord((prev) => ({ ...prev, start: { time: newStartTimeObj } }));
	};

	const changeEndTime = (newEndTime) => {
		const newEndTimeObj = DateTime.fromISO(newEndTime).setZone(timezone).toISO();
		setTimeRecord((prev) => ({ ...prev, end: { time: newEndTimeObj } }));
	};

	function getStartAndEndTimeFromWorkingHours() {
		let startTime = timeRecord?.start?.time;
		let endTime = timeRecord?.end?.time;
		if (workingHours?.enabled && workingHours?.start && workingHours?.end) {
			const { hour: startTimeHour, minute: startTimeMinute } = DateTime.fromISO(workingHours?.start);
			const { hour: endTimeHour, minute: endTimeMinute } = DateTime.fromISO(workingHours?.end);
			startTime = DateTime.fromISO(startTime).set({ hour: startTimeHour, minute: startTimeMinute }).toISO();
			endTime = DateTime.fromISO(endTime).set({ hour: endTimeHour, minute: endTimeMinute }).toISO();
		} else {
			startTime = DateTime.fromISO(startTime).set({ hour: 9, minute: 0 }).toISO();
			endTime = DateTime.fromISO(endTime).set({ hour: 17, minute: 0 }).toISO();
		}
		return {
			startTime,
			endTime,
		};
	}
	const handleSaveActivity = async () => {
		setLoading(true);
		if (activityName?.value === CEDAR_TYPES.sickLeave) {
			const submittedSickLeaveAmout = calculateSubmittedSickLeaveAmount(changeRequests);
			if (submittedSickLeaveAmout > 0) {
				if (!sickNoteSwitcher) {
					dispatch(enableCard(true));
					dispatch(updateErrorCard(CARD.MAIN_ERROR, 'timeTracker.sick.warning'));
					setLoading(false);
					return;
				}
			}
		}

		let startTime = timeRecord?.start?.time;
		let endTime = timeRecord?.end?.time;
		if (day?.value === 'fullDay' && activityName?.value === CEDAR_TYPES.vacation) {
			({startTime, endTime} = getStartAndEndTimeFromWorkingHours());
		}

		if(sickNoteSwitcher && activityName?.value === CEDAR_TYPES.sickLeave){
			startTime = DateTime.fromISO(timeRecord?.start?.time).setZone(timezone).startOf('day').toISO();
			endTime = DateTime.fromISO(timeRecord?.end?.time).setZone(timezone).endOf('day').toISO();
		}

		updateTimeRecord({
			timeRecordID: timeRecord?._id,
			name: activityName?.value,
			notes,
			office: selectedOffice?._id,
			startTime,
			endTime,
		})
			.then(() => {
				dispatch(enableCard(true));
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'event.timeRecord.edit.successHeader', 'event.timeRecord.edit.success'));
				history.goBack();
			})
			.catch((err) => {
				//Handling overlapping Time periods
				if (err.response && err.response.status === 412) {
					console.log('An error occured during saving edited time record ', err);
					dispatch(enableCard(true));
					dispatch(updateErrorCard(CARD.MAIN_ERROR, 'event.timeRecord.edit.notAllowedError', err));
					setLoading(false);
				} else {
					dispatch(enableCard(true));
					dispatch(updateErrorCard(CARD.MAIN_ERROR, 'event.timeRecordEditFailure', err));
				}
				setTimeError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleSubmitActivity = () => {
		setLoading(true);
		if (activityName?.value === CEDAR_TYPES.sickLeave) {
			const submittedSickLeaveAmout = calculateSubmittedSickLeaveAmount(changeRequests);
			console.log(submittedSickLeaveAmout);
			if (submittedSickLeaveAmout > 0) {
				if (!sickNoteSwitcher) {
					dispatch(enableCard(true));
					dispatch(updateErrorCard(CARD.MAIN_ERROR, 'timeTracker.sick.warning'));
					setLoading(false);
					return;
				}
			}
		}
		let startTime = timeRecord?.start?.time;
		let endTime = timeRecord?.end?.time;
		if (day?.value === 'fullDay' && activityName?.value === CEDAR_TYPES.vacation) {
			({ startTime, endTime } = getStartAndEndTimeFromWorkingHours());
		}

		if(sickNoteSwitcher && activityName?.value === CEDAR_TYPES.sickLeave){
			startTime = DateTime.fromISO(timeRecord?.start?.time).setZone(timezone).startOf('day').toISO();
			endTime = DateTime.fromISO(timeRecord?.end?.time).setZone(timezone).endOf('day').toISO();
		}
		
		addTimeRecord({
			name: activityName?.value,
			notes: notes,
			office: selectedOffice?._id,
			start: startTime,
			end: endTime,
		})
			.then(() => {
				dispatch(enableCard(true));
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'event.timeRecord.add.successHeader', 'event.timeRecord.add.success'));
				history.goBack();
			})
			.catch((err) => {
				dispatch(enableCard(true));
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'event.timeRecord.add.failure', err));
				setTimeError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDeleteActivity = () => {
		dispatch(enableCard(true));
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_EXISTING_TIME_RECORD,
				{
					headerKey: 'event.timeRecord.edit.successHeader',
					confirmButtonKey: 'event.timeRecord.edit.deleteWarningDecisionYes',
					infoKey: 'event.timeRecord.edit.deleteWarningDecisionText',
				},
				{ timeRecordID: timeRecord?._id, office: selectedOffice?._id },
			),
		);
	};


	const updateDisableFuture = () => {
		if (activityName?.value === CEDAR_TYPES.work || activityName?.value === CEDAR_TYPES.pause) {
			setDisableFuture(true);
		}
		else if (activityName?.value === CEDAR_TYPES.vacation) {
			setDisableFuture(false);
		} else {
			const disable = !futureAllowedTypes.includes(activityName?.value);
			setDisableFuture(disable);
		}
	};

	const shouldDisableFuture = (activityType) => {
		return futureAllowedTypes.includes(activityType);
	}
	const handleSickNoteSwitch = () => {
		setSickNoteSwitcher((prev) => !prev);
		if (!sickNoteSwitcher) {
			// Sick note is provided, allow future sick leaves
			setFutureAllowedTypes((prev) => [...prev, CEDAR_TYPES.sickLeave]);
		} else {
			// No sick note, restrict future sick leaves
			setFutureAllowedTypes([CEDAR_TYPES.work, CEDAR_TYPES.pause]);
		}
	};
	return (
		<>
			{editActivity && (
				<SecondaryHeader backButtonClickHandler={backButtonHandler} headerTranslationKey={t('timeTracker.settings.edit.header')} />
			)}
			{newActivity && (
				<SecondaryHeader backButtonClickHandler={backButtonHandler} headerTranslationKey={t('timeTracker.settings.new.header')} />
			)}
			<div className="time-tracker-settings-wrapper">
				<div className="activity-container">

					<div className="activity-name-container">
						<Typography className="tt-section-title margin-bottom">
							{t('timeTracker.settings.edit.name')}
						</Typography>
						<Select
							className="time-tracker-activity-dropdown"
							options={workOptions}
							styles={selectStyleOptions}
							defaultValue={activityName}
							onChange={(e) => setActivityName(e)}
							isSearchable={false}
						/>
					</div>
					{(activityName?.value === CEDAR_TYPES.vacation || activityName?.value === CEDAR_TYPES.sickLeave) && (
						<>
							{activityName?.value !== CEDAR_TYPES.sickLeave &&
								<div className="activity-days-container">
									<Typography className="tt-section-title margin-bottom inset-top">
										{t('timeTracker.settings.edit.fullPartialDays')}
									</Typography>
									<Select
										className="time-tracker-activity-dropdown"
										options={daysOptions}
										styles={selectStyleOptions}
										defaultValue={day}
										onChange={(e) => setDay(e)}
										isSearchable={false}
									/>
								</div>
							}
							{activityName?.value === CEDAR_TYPES.sickLeave && (
								<div className="sick-notes-container">
									<div className="sick-note">
										<Typography>
											{t('timeTracker.settings.sick.header')}
										</Typography>
										<Switcher
											id={'sick-notes-switch'}
											isOn={sickNoteSwitcher}
											onColor='var(--color-main)'
											handleToggle={handleSickNoteSwitch}
										/>
									</div>
									<Typography variant="title-small">
										{t('timeTracker.settings.sick.description')}
									</Typography>
								</div>
							)}
						</>
					)}
					<div className="activity-date-times">
						<Typography className="tt-section-title inset">
							{t('timeTracker.settings.edit.dateAndTime')}
						</Typography>
						<DateTimePicker
							wrapperId={'date-times'}
							startTime={moment(timeRecord?.start?.time).tz(timezone)}
							endTime={moment(timeRecord?.end?.time).tz(timezone)}
							changeStartTime={changeStartTime}
							changeEndTime={changeEndTime}
							timezone={timezone}
							disableFuture={disableFuture}
							maxDate={disableFuture ? currentTimeInISO : null}
							customTimePickerPlacement={'bottomLeft'}
							error={timeError}
							minuteStep={1}
							disabledTimes = {disabledTimes}
							onlyStartTime={activityName?.value === CEDAR_TYPES.sickLeave && sickNoteSwitcher === false}
							onlyDate={day?.value === 'fullDay' && activityName?.value === CEDAR_TYPES.vacation}

						/>
					</div>
					<div className="activity-notes-container">
						<Textarea
							cssClass="activity-notes"
							placeholder={'timeTracker.settings.notes.placeholder'}
							cols="30"
							rows="10"
							defaultValue={notes}
							labelKey={t('timeTracker.settings.notes.header')}
							maximumCharacterLimit={limit.maximumTextLimitTimeTrackerNotes}
							maxLength={limit.maximumTextLimitTimeTrackerNotes}
							characterLimitPosition={'bottom'}
							characterLimitVersion={2}
							changeHandler={(value) => {
								setNotes(value);
							}}
							onFocus={handleFocus}
						/>
						<div className={'placeholderForVirtualKeyboard' + cssForHiddenDiv}></div>
					</div>
				</div>
			</div>

			<div className="time-tracker-buttons-wrapper">
				<div className="activity-buttons-container">
					{editActivity && (
						<>
							<Button
								cssClass={'activity-button-delete'}
								variant={'primary'}
								translationKey={'timeTracker.settings.edit.buttons.delete'}
								clickHandler={handleDeleteActivity}
								loading={loading}
							/>
							<Button
								variant={'primary'}
								translationKey={'timeTracker.settings.edit.buttons.save'}
								clickHandler={handleSaveActivity}
								loading={loading}
								disabled={timeError}
							/>
						</>
					)}
					{newActivity && (
						<Button
							variant={'primary'}
							translationKey={'timeTracker.settings.new.buttons.submit'}
							clickHandler={handleSubmitActivity}
							loading={loading}
							disabled={timeError}
						/>
					)}
				</div>
			</div>
		</>
	);
}
export default TimeTrackerSettings;
