export const CARD = {
	CHECKIN: 'checkInCard',
	REPORT_PROBLEM: 'reportProblem',
	PLACEHOLDER: 'cardPlaceholder',
	SUMMARY_REPORT_PROBLEM: 'summaryReportProblem',
	SUMMARY_APP_REPORT_PROBLEM: 'summaryAppReportProblem',
	SUMMARY_REPORT_FACILITY_PROBLEM: 'summaryReportFacilityProblem',
	MAIN_SUCCESS: 'mainSuccess',
	MAIN_ERROR: 'mainError',
	MEMBER_BADGE: 'memberBadge',
	MEMBER_BADGE_PLACEHOLDER: 'memberBadgePlaceholder',
	USER_REVIEW: 'userReview',
	RECURRING_BOOKING_SETTINGS: 'recurringBookingSettings',
	CONFIRM_RECURRING_CONFLICTS: 'confirmRecurringConflicts',
	QUICK_TOUR_INFO: 'quickTourInfo',
	CEYLON_INFO: 'ceylonInfo',
	CEYLON_PROCESS: 'ceylonProcess',
	CEYLON_FAILED_VALIDATION: 'ceylonFailedValidation',
	CONFIRM_CHECKIN: 'confirmCheckin',
	EXTEND_TIME: 'extendTime',
	BROADCAST_NOTIFICATION_CARD: 'broadcastNotificationCard',
	NOT_FOUND_CARD: 'notFoundCard',
	EMERGENCY_CARD: 'emergencyCard',
	PAYMENT_INTENT: 'paymentIntent',
	PAYMENT_REDIRECT: 'paymentRedirect',
	UNLOCK_NUKI: 'unlockNukiCard',
	EVENT_FEEDBACK: 'eventFeedback',
	VIEW_OFFICE_HOURS: 'viewOfficeHours',
	HANDLE_NOT_FITTING_CAPACITY: 'handleNotFittingCapacity',
	CHECKIN_DISABLED: 'checkinDisabled',
	CONFIRM_ACTION: 'confirmAction',
	BOOTH_ALREADY_SCANNED: 'boothAlreadyScanned',
	TIME_TRACKING_CHANGE_REQUEST: 'timeTrackingChangeRequest',
	TIME_TRACKING_OFFICE_CHANGE: 'timeTrackingOfficeChange',
	TIME_TRACKING_WORKING_SUMMARY: 'timeTrackingWorkingSummary'
};
