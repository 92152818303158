import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {enableBottomMenu} from '../../../store/auth';
import {loggingOut} from '../../../api/index';
import Button from '../../Common/Button';
import SettingsItem from './SettingsItem';
import SecondaryHeader from '../../Common/Header/SecondaryHeader';
import {getAuthContext} from '../../../api/additionals';
import doorjames_logo from '../../../image/doorjames_logo.svg';

const Settings = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const multiOrganization = window.localStorage.getItem('multiOrganization');
	const userData = useSelector((state) => state.auth.data.userData);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const [orgLoading, setOrgLoading] = useState();
	const [error, setError] = useState();

	const userLogout = () => {
		loggingOut()
			.then(() => {
				clearLocalStorage();
				return window.location.assign(ROUTES.DEFAULT);
			})
			.catch((error) => {
				console.log(error);
				if (error.response.data?.errorName === 'sessionNotFound') {
					return window.location.assign(ROUTES.DEFAULT);
				}
			});
	};

	const orgSwitcher = async () => {
		setOrgLoading(true);
		try {
			const authContext = await getAuthContext(userData?.email, undefined, userData?.email.split('@')[1]);
			const data = authContext.data;
			if (data?.length > 1) {
				window.localStorage.setItem('multiOrganization', 'true');
				history.push({
					pathname: '/organizationSwitcher',
					state: {organizations: data, email: userData?.email, openedFromSettings: true},
				});
				return;
			} else {
				userLogout();
			}
		} catch (error) {
			setError(error.response?.data?.name && t('errorMessages.apiCalls.errorLoginStrategies'));
			setOrgLoading(false);
		}
	};
	const currentVersion = window.localStorage.getItem('currentVersion');
	const lastUpdate = window.localStorage.getItem('lastVersionUpdate');

	useEffect(() => {
		dispatch(enableBottomMenu(false));
	});

	const backButtonHandler = () => {
		history.goBack();
	};

	const goToLanguageSettings = () => {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.LANGUAGE_SETTINGS_PAGE);
	};

	const goToBookingSettings = () => {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.BOOKING_SETTINGS_PAGE);
	};

	const goToTimeTrackingSettings = () => {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.TIME_TRACKING_SETTINGS_PAGE);
	}

	const goToDataPrivacySettings = () => {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.DATA_PRIVACY_SETTINGS_PAGE);
	};

	const goToNotificationSettings = () => {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.APP_SETTINGS_PAGE + ROUTES.NOTIFICATION_SETTINGS_PAGE);
	};

	return (
		<>
			<SecondaryHeader headerTranslationKey={'profile.headers.settings'} backButtonClickHandler={backButtonHandler} />
			<div className="settings-wrapper">
				<div className="settings-content">
					<SettingsItem
						heading={'settings.languageSettingHeader'}
						iconName={'globe'}
						iconCssClass={'settings-item-left-icon icon-purple'}
						onClick={goToLanguageSettings}
					/>

					<SettingsItem
						heading={'settings.bookingSettingHeader'}
						iconName={'user-check'}
						iconCssClass={'settings-item-left-icon icon-purple'}
						onClick={goToBookingSettings}
					/>

					{selectedOffice?.cedar?.enabled && selectedOffice?.cedar?.options?.individualWorkTime && (
						<SettingsItem
							heading={'settings.timeTrackingSettingHeader'}
							iconName={'stopwatch'}
							iconCssClass={'settings-item-left-icon icon-purple'}
							onClick={goToTimeTrackingSettings}
						/>
					)}

					<SettingsItem
						heading={'settings.notificationSettingHeader'}
						iconName={'bell'}
						iconCssClass={'settings-item-left-icon icon-purple'}
						onClick={goToNotificationSettings}
					/>

					<SettingsItem
						heading={'settings.dataPrivacySettingHeader'}
						iconName={'shield'}
						iconCssClass={'settings-item-left-icon icon-purple'}
						onClick={goToDataPrivacySettings}
					/>

					<div className="settings-logout-button-wrapper">
						{error && <p className="error-info">{error}</p>}
						{multiOrganization && (
							<Button
								variant={'secondary'}
								height={'regular'}
								width={'full'}
								cssClass={'buttonLogout'}
								translationKey={'settings.buttonOrgSwitch'}
								clickHandler={orgSwitcher}
								iconName={!orgLoading && 'shuffle'}
								iconColor={'purple'}
								loading={orgLoading}
							/>
						)}
						<Button
							variant={'primary'}
							height={'regular'}
							width={'full'}
							cssClass={'buttonLogout'}
							translationKey={'settings.buttonLogout'}
							clickHandler={userLogout}
						/>
					</div>
					<div className="version-information">
						<p>{t('settings.versionLabel')}</p>
						{currentVersion} -- ({lastUpdate})
					</div>
				</div>
			</div>
		</>
	);
};

export default Settings;

export function clearLocalStorage() {
	const emailForRemember = window.localStorage.getItem('loginEmail');
	const hourFormat = window.localStorage.getItem('timeFormat12hrs');
	const calendarFormat = window.localStorage.getItem('calendarFormatSunToSat');
	const multiOrganization = window.localStorage.getItem('multiOrganization');
	const organizationWhiteLabel = window.localStorage.getItem('organizationWhiteLabel');
	window.localStorage.clear();
	if (emailForRemember) window.localStorage.setItem('loginEmail', emailForRemember);
	if (hourFormat) window.localStorage.setItem('timeFormat12hrs', hourFormat);
	if (calendarFormat) window.localStorage.setItem('calendarFormatSunToSat', calendarFormat);
	if (multiOrganization) window.localStorage.setItem('multiOrganization', multiOrganization);
	if (organizationWhiteLabel) window.localStorage.setItem('organizationWhiteLabel', organizationWhiteLabel);
}

export function updateOrganizationWhiteLabel(organization) {
	if (organization?.biro?.showCGILogin) {
		window.localStorage.setItem('organizationWhiteLabel', 'CGI');
		removeDoorJamesLogo();
	} else {
		if (window.localStorage.getItem('organizationWhiteLabel')) {
			window.localStorage.removeItem('organizationWhiteLabel');
		}
		addDoorJamesLogo();
	}
}

function addDoorJamesLogo() {
	// Add CSS to the HTML head
	const styleElement = document.createElement('style');
	styleElement.setAttribute('id', 'doorJamesLogo');
	styleElement.innerHTML = `
			  @media all and (min-width: 900px) {
				html::after {
				  content: '';
				  background: url(${doorjames_logo}) no-repeat;
				  pointer-events: none;
				  background-size: 100% 100%;
				  position: absolute;
				  width: 120px;
				  height: 120px;
				  left: 60px;
				  bottom: 20px;
				  z-index: -1;
				}
			  }
			`;
	document.head.appendChild(styleElement);
}

function removeDoorJamesLogo() {
	// Remove CSS from the HTML head
	const styleElement = document.getElementById('doorJamesLogo');
	if (styleElement) {
		styleElement.remove();
	}
}
